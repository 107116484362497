export default {
  components: {},
  data() {
    return {
      active: '',
      items: [{
        title: '首页',
        normal: require('@/assets/foot/cour_b.png'),
        active: require('@/assets/foot/cour_g.png'),
        path: '/'
      }, {
        title: '加油站',
        normal: require('@/assets/foot/lesson_b.png'),
        active: require('@/assets/foot/lesson_g.png'),
        path: '/c_profit'
      }, {
        title: '教练工具',
        normal: require('@/assets/foot/card_b.png'),
        active: require('@/assets/foot/card_g.png'),
        path: '/c_recruit'
      }, {
        title: '扩展服务',
        normal: require('@/assets/pro/skz.png'),
        active: require('@/assets/pro/skz-n.png'),
        path: '/prod'
      }, {
        title: '个人中心',
        normal: require('@/assets/foot/user_b.png'),
        active: require('@/assets/foot/user_g.png'),
        path: '/c_user'
      }]
    };
  },
  watch: {
    '$route.path': {
      handler(val) {
        if (val !== this.active) {
          this.active = val;
        }
      },
      immediate: true
    }
  }
};